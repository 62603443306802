import { EventType, Filter, Listener } from '@ethersproject/abstract-provider';

import {
  AlchemyEventFilter,
  AlchemyEventType,
  AlchemyMinedTransactionsAddress,
  AlchemyMinedTransactionsEventFilter,
  AlchemyPendingTransactionsEventFilter,
  AlchemySubscription,
  NonEmptyArray
} from '../types/types';
import {
  ALCHEMY_EVENT_TYPES,
  ALCHEMY_MINED_TRANSACTIONS_EVENT_TYPE,
  ALCHEMY_PENDING_TRANSACTIONS_EVENT_TYPE
} from './internal-types';

/**
 * DO NOT MODIFY.
 *
 * Event class copied directly over from ethers.js's `BaseProvider` class.
 *
 * This class is used to represent events and their corresponding listeners. The
 * SDK needs to extend this class in order to support Alchemy's custom
 * Subscription API types. The original class is not exported by ethers. Minimal
 * changes have been made in order to get TS to compile.
 */
class Event {
  readonly listener: Listener;
  readonly once: boolean;
  readonly tag: string;

  _lastBlockNumber: number;
  _inflight: boolean;

  constructor(tag: string, listener: Listener, once: boolean) {
    this.listener = listener;
    this.tag = tag;
    this.once = once;
    this._lastBlockNumber = -2;
    this._inflight = false;
  }

  get event(): EventType {
    switch (this.type) {
      case 'tx':
        return this.hash!;
      case 'filter':
        return this.filter!;
      default:
        return this.tag;
    }
  }

  get type(): string {
    return this.tag.split(':')[0];
  }

  get hash(): string {
    const comps = this.tag.split(':');
    if (comps[0] !== 'tx') {
      throw new Error('Not a transaction event');
    }
    return comps[1];
  }

  get filter(): Filter {
    const comps = this.tag.split(':');
    if (comps[0] !== 'filter') {
      throw new Error('Not a transaction event');
    }
    const address = comps[1];

    const topics = deserializeTopics(comps[2]);
    const filter: Filter = {};

    if (topics.length > 0) {
      filter.topics = topics;
    }
    if (address && address !== '*') {
      filter.address = address;
    }

    return filter;
  }

  pollable(): boolean {
    const PollableEvents = ['block', 'network', 'pending', 'poll'];
    return this.tag.indexOf(':') >= 0 || PollableEvents.indexOf(this.tag) >= 0;
  }
}

/**
 * Wrapper class around the ethers `Event` class in order to add support for
 * Alchemy's custom subscriptions types.
 *
 * The serialization and deserialization mechanism requires the order of the
 * fields to be the same across different {@link ALCHEMY_EVENT_TYPES}. Before
 * using a getter on a new event filter, make sure that the position of the
 * field in serialization is correct.
 *
 * The getters on this class deserialize the event tag generated by
 * {@link getAlchemyEventTag} into the original fields passed into the event.
 */
export class EthersEvent extends Event {
  /**
   * Converts the event tag into the original `fromAddress` field in
   * {@link AlchemyPendingTransactionsEventFilter}.
   */
  get fromAddress(): string | string[] | undefined {
    const comps = this.tag.split(':');
    if (comps[0] !== ALCHEMY_PENDING_TRANSACTIONS_EVENT_TYPE) {
      return undefined;
    }
    if (comps[1] && comps[1] !== '*') {
      return deserializeAddressField(comps[1]);
    } else {
      return undefined;
    }
  }

  /**
   * Converts the event tag into the original `toAddress` field in
   * {@link AlchemyPendingTransactionsEventFilter}.
   */
  get toAddress(): string | string[] | undefined {
    const comps = this.tag.split(':');
    if (comps[0] !== ALCHEMY_PENDING_TRANSACTIONS_EVENT_TYPE) {
      return undefined;
    }
    if (comps[2] && comps[2] !== '*') {
      return deserializeAddressField(comps[2]);
    } else {
      return undefined;
    }
  }

  /**
   * Converts the event tag into the original `hashesOnly` field in
   * {@link AlchemyPendingTransactionsEventFilter} and {@link AlchemyMinedTransactionsEventFilter}.
   */
  get hashesOnly(): boolean | undefined {
    const comps = this.tag.split(':');
    if (!ALCHEMY_EVENT_TYPES.includes(comps[0])) {
      return undefined;
    }
    if (comps[3] && comps[3] !== '*') {
      return comps[3] === 'true';
    } else {
      return undefined;
    }
  }

  get includeRemoved(): boolean | undefined {
    const comps = this.tag.split(':');
    if (comps[0] !== ALCHEMY_MINED_TRANSACTIONS_EVENT_TYPE) {
      return undefined;
    }
    if (comps[2] && comps[2] !== '*') {
      return comps[2] === 'true';
    } else {
      return undefined;
    }
  }

  get addresses(): NonEmptyArray<AlchemyMinedTransactionsAddress> | undefined {
    const comps = this.tag.split(':');
    if (comps[0] !== ALCHEMY_MINED_TRANSACTIONS_EVENT_TYPE) {
      return undefined;
    }
    if (comps[1] && comps[1] !== '*') {
      return deserializeAddressesField(comps[1]);
    } else {
      return undefined;
    }
  }
}

export function isAlchemyEvent(
  event: AlchemyEventType
): event is
  | AlchemyMinedTransactionsEventFilter
  | AlchemyPendingTransactionsEventFilter {
  return typeof event === 'object' && 'method' in event;
}

/**
 * Creates a string representation of an `alchemy_pendingTransaction`
 * subscription filter that is compatible with the ethers implementation of
 * `getEventTag()`. The method is not an exported function in ethers, which is
 * why the SDK has its own implementation.
 *
 * The event tag is then deserialized by the SDK's {@link EthersEvent} getters.
 *
 * @example
 *   ```js
 *   // Returns 'alchemy-pending-transactions:0xABC:0xDEF|0xGHI:true'
 *   const eventTag =  getAlchemyEventTag(
 *   {
 *     "method": "alchemy_pendingTransactions",
 *     "fromAddress": "0xABC",
 *     "toAddress": ["0xDEF", "0xGHI"],
 *     "hashesOnly: true
 *   });
 *   ```;
 *
 * @param event
 * @internal
 */
export function getAlchemyEventTag(event: AlchemyEventType): string {
  if (!isAlchemyEvent(event)) {
    throw new Error('Event tag requires AlchemyEventType');
  }

  if (event.method === AlchemySubscription.PENDING_TRANSACTIONS) {
    return serializePendingTransactionsEvent(event);
  } else if (event.method === AlchemySubscription.MINED_TRANSACTIONS) {
    return serializeMinedTransactionsEvent(event);
  } else {
    throw new Error(`Unrecognized AlchemyFilterEvent: ${event}`);
  }
}

export function verifyAlchemyEventName(eventName: AlchemyEventFilter): void {
  if (!Object.values(AlchemySubscription).includes(eventName.method)) {
    throw new Error(
      `Invalid method name ${
        eventName.method
      }. Accepted method names: ${Object.values(AlchemySubscription)}`
    );
  }
}

/**
 * Serializes the provided {@link AlchemyPendingTransactionsEventFilter} into an ethers
 * compatible event tag.
 *
 * For the example event:
 * ```
 *  {
 *     "method": "alchemy_pendingTransactions",
 *     "fromAddress": "0xABC",
 *     "toAddress": ["0xDEF", "0xGHI"],
 *     "hashesOnly: true
 *   }
 * ```
 *
 * The resulting serialization is:
 * `alchemy_pendingTransactions:0xABC:0xDEF|0xGHI:true`
 *
 * If a field is omitted, it is replaced with a `*`.
 */
function serializePendingTransactionsEvent(
  event: AlchemyPendingTransactionsEventFilter
): string {
  const fromAddress = serializeAddressField(event.fromAddress);
  const toAddress = serializeAddressField(event.toAddress);
  const hashesOnly = serializeBooleanField(event.hashesOnly);
  return (
    ALCHEMY_PENDING_TRANSACTIONS_EVENT_TYPE +
    ':' +
    fromAddress +
    ':' +
    toAddress +
    ':' +
    hashesOnly
  );
}

/**
 * Serializes the provided {@link AlchemyPendingTransactionsEventFilter} into an ethers
 * compatible event tag.
 *
 * For the example event:
 * ```
 *  {
 *     "method": "alchemy_minedTransactions",
 *     "addresses": [
 *       {
 *         from: "0xABC"
 *       },
 *       {
 *         to: "0xDEF",
 *         from: "0x123"
 *       }
 *     ]
 *     "includeRemoved": false
 *     "hashesOnly: true
 *   }
 * ```
 *
 * The resulting serialization is:
 * `alchemy_minedTransactions:*,0xABC|0xDEF,0x123:false:true`
 *
 * If a field is omitted, it is replaced with a `*`.
 *
 * The `addresses` array is split by the '|' character, and each to/from address
 * pair is separated by a ',' character.
 *
 */
function serializeMinedTransactionsEvent(
  event: AlchemyMinedTransactionsEventFilter
): string {
  const addresses = serializeAddressesField(event.addresses);
  const includeRemoved = serializeBooleanField(event.includeRemoved);
  const hashesOnly = serializeBooleanField(event.hashesOnly);
  return (
    ALCHEMY_MINED_TRANSACTIONS_EVENT_TYPE +
    ':' +
    addresses +
    ':' +
    includeRemoved +
    ':' +
    hashesOnly
  );
}

function serializeAddressesField(
  addresses: AlchemyMinedTransactionsAddress[] | undefined
): string {
  if (addresses === undefined) {
    return '*';
  }

  return addresses
    .map(
      filter =>
        serializeAddressField(filter.to) +
        ',' +
        serializeAddressField(filter.from)
    )
    .join('|');
}

function serializeAddressField(
  field: string | Array<string> | undefined
): string {
  if (field === undefined) {
    return '*';
  } else if (Array.isArray(field)) {
    return field.join('|');
  } else {
    return field;
  }
}

function serializeBooleanField(field: boolean | undefined): string | undefined {
  if (field === undefined) {
    return '*';
  } else {
    return field.toString();
  }
}

export function deserializeTopics(data: string): any {
  if (data === '') {
    return [];
  }

  return data.split(/&/g).map(topic => {
    if (topic === '') {
      return [];
    }

    const comps = topic.split('|').map(topic => {
      return topic === 'null' ? null : topic;
    });

    return comps.length === 1 ? comps[0] : comps;
  });
}

function deserializeAddressField(data: string): string | string[] | undefined {
  if (data === '') {
    return undefined;
  }

  const addresses = data.split('|');
  return addresses.length === 1 ? addresses[0] : addresses;
}

function deserializeAddressesField(
  data: string
): NonEmptyArray<AlchemyMinedTransactionsAddress> | undefined {
  if (data === '') {
    return undefined;
  }

  // Perform a cast here since TS doesn't know we're guaranteed a non-empty
  // array from deserializing.
  return data
    .split('|')
    .map(addressStr => addressStr.split(','))
    .map(addressPair => ({
      ...(addressPair[0] !== '*' && { to: addressPair[0] }),
      ...(addressPair[1] !== '*' && { from: addressPair[1] })
    })) as NonEmptyArray<AlchemyMinedTransactionsAddress>;
}
